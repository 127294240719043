<template>
  <div class="fd-link-content flex items-center justify-between">
    <el-tooltip
      :disabled="!item.tooltip"
      :content="item.tooltip"
      effect="light"
      placement="right"
    >
      <div class="flex items-center w-full" :class="{ 'ml-1': item.isChild }">
        <div class="fd-link-prefix flex items-center mr-1">
          <slot name="prefix" />
        </div>

        <el-tooltip
          v-if="item.iconState"
          :content="item.stateLabel"
          effect="light"
          placement="top"
        >
          <icon v-bind="item.iconState" />
        </el-tooltip>
        <div class="pl-2 pr-4 w-10">
          <icon
            v-if="item.icon"
            :icon="item.icon"
            :class="{ 'off-open': item.iconOpen }"
          />
          <icon v-if="item.iconOpen" :icon="item.iconOpen" class="on-open" />
        </div>
        <span v-if="showTitle" class="truncate">{{ item.name }}</span>
      </div>
      <div class="fd-link-suffix">
        <slot name="suffix" />
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'FdLinkContent',
  props: {
    item: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  }
};
</script>
