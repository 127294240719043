<template>
  <el-container id="the-layout-admin">
    <the-default-layout>
      <the-account-bar slot="header" class="bg-dark-gray" is-admin>
        <span slot="title">admin</span>
        <notifications-panel-icon />
        <account-info-button button-class="button-charcoal" />
      </the-account-bar>

      <the-nav-bar slot="sidebar" theme="admin" :nav="navMenu" />
    </the-default-layout>
  </el-container>
</template>

<script>
import AccountInfoButton from '@/components/Auth/AccountInfoButton';
import NavToggle from '@/components/Core/NavToggle';
import TheAccountBar from '@/components/Core/Layout/TheAccountBar';
import TheDefaultLayout from '@/components/Core/Layout/TheDefaultLayout';
import TheNavBar from '@/components/Core/Layout/TheNavBar';

export default {
  name: 'TheLayoutAdmin',
  components: {
    AccountInfoButton,
    TheAccountBar,
    TheDefaultLayout,
    TheNavBar
  },
  mixins: [NavToggle]
};
</script>
