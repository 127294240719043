<template>
  <div class="iframe-v4-layout">
    <div class="iframe-loading">
      Please wait while we load the content...
      <icon
        :icon="loadingIcon"
        class="animated rotate infinite animate-hover ml-1"
      />
    </div>
    <iframe class="iframe-content" :src="url" allow="clipboard-write" />
  </div>
</template>

<script>
import { arrowsCw as loadingIcon } from '@/vendor/icons';

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingIcon
    };
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.iframe-v4-layout {
  flex-grow: 1;
  margin-top: 0 !important;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.iframe-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: $color-dark-silver;
  z-index: 1;
}

.iframe-content {
  position: relative;
  width: 100%;
  height: 99.9%;
  overflow: hidden;
  border: none;
  background: none;
  z-index: 2;
}
</style>
