<template>
  <div
    id="the-nav-bar"
    :class="{
      'is-collapsed': isCollapsed,
      'theme-light': theme === 'light',
      'theme-admin': theme === 'admin'
    }"
  >
    <el-menu
      class="sidebar-menu"
      :collapse="isCollapsed"
      :background-color="backgroundColor"
      :collapse-transition="false"
      :unique-opened="true"
      :default-active="'1-0'"
    >
      <template v-for="(item, index) in nav">
        <nav-bar-item
          v-if="item"
          :key="`sidebar-menu-${item.name}`"
          :item="item"
          :index="'' + index"
          :collapsed="isCollapsed"
        />
      </template>
    </el-menu>

    <div class="sidebar-footer">
      <el-menu
        v-if="$can('takeover_suppliers')"
        class="sidebar-menu mb-5"
        :background-color="backgroundColor"
        :collapse="isCollapsed"
        :collapse-transition="false"
      >
        <nav-bar-item
          v-for="(item, index) in adminUtilMenu"
          :key="'util-' + item.name"
          :index="'' + index"
          :item="item"
          :collapsed="isCollapsed"
        />
      </el-menu>

      <slot name="footer" />

      <div class="collapse-toggle">
        <a
          v-if="isCollapsed"
          type="text"
          class="text-center block"
          @click="setCollapsed(false)"
        >
          <icon :icon="angleDoubleRight" />
        </a>

        <el-button
          v-else
          type="text"
          class="close-button"
          size="small"
          @click="setCollapsed(true)"
        >
          Close
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { KeyEventBus } from '@/utils/keyEvents';
import {
  angleDoubleRight,
  basket as buyerIcon,
  flyering as supplierIcon,
  flytedesk as adminIcon
} from '@/vendor/icons';
import NavBarItem from './NavBarItem';

export default {
  name: 'TheNavBar',

  components: {
    NavBarItem
  },
  props: {
    nav: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      default: null
    },
    backgroundColor: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isCollapsed: true,
      KeyEventBus,

      // Icons
      adminIcon,
      angleDoubleRight,
      buyerIcon,
      supplierIcon
    };
  },
  computed: {
    adminUtilMenu() {
      return [
        {
          name: 'Publisher Takeover',
          icon: supplierIcon,
          tooltipOnCollapsed: true,
          tooltip: 'Takeover a Publisher Account (ctrl + alt + p)',
          action() {
            KeyEventBus.$emit('takeover-supplier');
          }
        },
        {
          name: 'Advertiser Takeover',
          icon: buyerIcon,
          tooltipOnCollapsed: true,
          tooltip: 'Takeover an Advertiser Account (ctrl + alt + b)',
          action() {
            KeyEventBus.$emit('takeover-buyer');
          }
        },
        {
          name: 'Toggle Admin Menu',
          icon: adminIcon,
          tooltipOnCollapsed: true,
          tooltip: 'Toggle Admin Menu (ctrl + alt + a)',
          action() {
            KeyEventBus.$emit('toggle-admin-nav');
          }
        }
      ];
    }
  },
  mounted() {
    let collapsed = this.$cookie('collapse-menu');

    if (collapsed === null) {
      // If the cookie doesn't exist, set to the current state
      collapsed = this.isCollapsed;
    } else {
      collapsed = collapsed === 'true';
    }

    // set the initial state
    this.setCollapsed(collapsed);
  },
  methods: {
    setCollapsed(value) {
      this.$cookie('collapse-menu', value);
      this.isCollapsed = value;
    }
  }
};
</script>

<style lang="scss">
@import '~@/scss/_variables';

#the-nav-bar {
  position: relative;
  width: 14.5em;
  height: 100%;
  transition: width 0.3s;
  z-index: 501;

  &.is-collapsed {
    width: 3.6em;
  }

  & > .el-menu {
    background: $color-dark-gray;
    padding-bottom: 5em;

    // Sub Menu Styling
    .el-menu {
      .el-submenu__title,
      .el-menu-item {
        background: $color-charcoal;
        padding-left: 0 !important; //only using this to override element-ui
      }
    }
  }

  .fd-link-item {
    color: white;
  }

  .is-active {
    .fd-link-item {
      color: $color-blue;
    }

    .el-menu-item:not(.is-active) {
      .fd-link-item {
        color: white;
      }
    }
  }

  .el-submenu__title,
  .el-menu-item {
    &:hover {
      .fd-link-item {
        color: $color-blue;
      }
    }
  }

  .sidebar-footer {
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 1em;
    width: 100%;
    color: white;

    .sidebar-menu {
      background: transparent;
    }

    a {
      color: white;

      &:hover {
        opacity: 0.5;
      }
    }

    .close-button {
      color: white;
      border-radius: 10px;
      border: 1px solid white;
      width: 90%;
      max-width: 8em;
    }
  }

  &.theme-light {
    background: $color-very-light-silver;
    color: $text;

    & > .el-menu {
      background: $color-very-light-silver;

      // Sub Menu Styling
      .el-menu {
        .el-submenu__title,
        .el-menu-item {
          background: $color-light-silver;
        }
      }
    }

    .fd-link-item {
      color: $text;

      &:hover {
        color: $color-blue;
      }
    }

    .is-active {
      .fd-link-item {
        color: $color-blue;
      }

      .el-menu-item:not(.is-active) {
        .fd-link-item {
          color: $text;

          &:hover {
            color: $color-blue;
          }
        }
      }
    }

    .el-submenu__title,
    .el-menu-item {
      &:hover {
        .fd-link-item {
          color: $color-blue;
        }
      }
    }

    .sidebar-footer {
      color: $text;

      a {
        color: $text;
      }

      .close-button {
        color: $text;
        border-color: $text;
      }
    }
  }

  &.theme-admin {
    background: white;
    color: $color-dark-silver;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    & > .el-menu {
      background: white;

      // Sub Menu Styling
      .el-menu {
        .el-submenu__title,
        .el-menu-item {
          background: $color-very-light-silver;
        }
      }
    }

    .fd-link-item {
      color: $color-dark-silver;

      &:hover {
        color: $color-blue;
      }
    }

    .is-active {
      .fd-link-item {
        color: $color-blue;
      }

      .el-menu-item:not(.is-active) {
        .fd-link-item {
          color: $color-dark-silver;

          &:hover {
            color: $color-blue;
          }
        }
      }
    }

    .el-submenu__title,
    .el-menu-item {
      &:hover {
        .fd-link-item {
          color: $color-blue;
        }
      }
    }

    .sidebar-footer {
      color: $color-dark-silver;

      a {
        color: $color-dark-silver;
      }

      .close-button {
        color: $color-dark-silver;
        border-color: $color-dark-silver;
      }
    }
  }
}

@media print {
  #the-nav-bar {
    display: none;
  }
}
</style>
