<template>
  <section class="page-content p-0">
    <iframe-v4-layout :url="url" />
  </section>
</template>

<script>
import IframeV4Layout from '@/components/Core/IframeV4Layout';
import { getAuthorizationToken } from '@/utils/authorization';

export default {
  components: { IframeV4Layout },
  computed: {
    url() {
      const jwt = getAuthorizationToken();
      return this.$apiUrl + `/admin-v4/location-search?jwt_token=${jwt}`;
    }
  }
};
</script>
