<template>
  <component
    :is="item.children ? 'el-submenu' : 'el-menu-item'"
    :index="index + (item.children ? '' : '-item')"
    :data-fd-index="index"
    :show-timeout="1"
  >
    <template v-if="item.children">
      <template slot="title">
        <el-menu-item :index="index + '-item'">
          <fd-link :item="item" :show-title="!collapsed" waves>
            <template slot="prefix">
              <icon v-if="item.iconPrefix" :icon="item.iconPrefix" />
            </template>

            <template slot="suffix">
              <icon v-if="item.iconSuffix" :icon="item.iconSuffix" />
            </template>
          </fd-link>
        </el-menu-item>
      </template>

      <!-- naming the index 'index' would be a duplicate and cause a bug -->
      <template v-for="(child, subIndex) in item.children">
        <nav-bar-item
          v-if="child"
          :key="`nav-bar-item-${index}-${subIndex}`"
          :index="`${index}-${subIndex}`"
          :item="child"
        />
      </template>
    </template>

    <template v-else>
      <el-tooltip
        placement="right"
        :content="item.tooltip || item.name"
        effect="light"
        :disabled="item.tooltipOnCollapsed !== false && !collapsed"
      >
        <fd-link :item="item" :show-title="!collapsed" waves>
          <template slot="prefix">
            <icon v-if="item.iconPrefix" :icon="item.iconPrefix" />
          </template>

          <template slot="suffix">
            <icon v-if="item.iconSuffix" :icon="item.iconSuffix" />
          </template>
        </fd-link>
      </el-tooltip>
    </template>
  </component>
</template>

<script>
import FdLink from '@/components/Core/FdLink';

export default {
  name: 'NavBarItem',

  components: {
    FdLink
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    collapsed: Boolean
  }
};
</script>
