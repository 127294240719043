<template>
  <div class="fd-link" :class="{ 'fd-waves': waves !== undefined }">
    <!-- The route is a part of the SPA and should link to a component -->
    <router-link v-if="type === 'router'" :to="linkTo" class="fd-link-item">
      <fd-link-content :item="item" :show-title="showTitle">
        <template slot="prefix">
          <slot name="prefix" />
        </template>

        <template slot="suffix">
          <slot name="suffix" />
        </template>
      </fd-link-content>
    </router-link>

    <!-- if the route alias is not a page in the vue-router routes, then assume it is an external link -->
    <a
      v-else-if="type === 'link'"
      :href="item.disabled ? null : item.href"
      class="fd-link-item"
      :class="{ 'is-disabled': item.disabled }"
      :target="item.target"
      :title="item.title"
      :disabled="item.disabled"
    >
      <fd-link-content :item="item" :show-title="showTitle">
        <template slot="prefix">
          <slot name="prefix" />
        </template>

        <template slot="suffix">
          <slot name="suffix" />
        </template>
      </fd-link-content>
    </a>

    <!-- if the route alias is not a page in the vue-router routes, then assume it is an external link -->
    <a v-else-if="type === 'action'" class="fd-link-item" @click="item.action">
      <fd-link-content :item="item" :show-title="showTitle">
        <template slot="prefix">
          <slot name="prefix" />
        </template>

        <template slot="suffix">
          <slot name="suffix" />
        </template>
      </fd-link-content>
    </a>

    <!-- If the item is not a link (just a menu title), render without linking... -->
    <div v-else class="fd-link-item" :class="{ 'is-disabled': item.disabled }">
      <fd-link-content :item="item" :show-title="showTitle">
        <template slot="prefix">
          <slot name="prefix" />
        </template>

        <template slot="suffix">
          <slot name="suffix" />
        </template>
      </fd-link-content>
    </div>
  </div>
</template>

<script>
import Waves from 'node-waves';
import FdLinkContent from './FdLinkContent';

export default {
  name: 'FdLink',

  components: {
    FdLinkContent
  },
  props: {
    // The link object
    item: {
      type: Object,
      required: true
    },
    // Show the title on hover
    showTitle: {
      type: Boolean,
      default: true
    },
    // Toggle the CSS waves effect
    waves: Boolean
  },

  computed: {
    type() {
      if (!this.disabled) {
        if (this.item.component || this.item.route) return 'router';

        if (this.item.href) return 'link';

        if (this.item.action) return 'action';
      }

      return 'default';
    },

    linkTo() {
      if (this.item.route) return this.item.route;

      if (typeof this.item.component === 'string') {
        return { name: this.item.component };
      } else {
        return this.item.component;
      }
    }
  },

  mounted() {
    Waves.attach('.fd-waves');
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables';

.fd-link {
  display: block;
}

.fd-link-item {
  display: block;
  padding: 0 0.875em;
}

.is-disabled {
  cursor: not-allowed;
  color: $color-dark-silver;
}
</style>
